<template>
  <div>
    <b-table-simple small>
      <b-thead>
        <b-tr>
          <b-th>First period</b-th>
          <b-th>Last period</b-th>
          <b-th>Target</b-th>
          <b-th>Transaction Type</b-th>
          <b-th v-if="showGroup">Group</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="match in data_matches" :key="match.TransactionMatchID">
          <b-td>{{ match.FirstValidAccountingPeriodID|formatAccountingPeriod }}</b-td>
          <b-td>{{ match.LastValidAccountingPeriodID|formatAccountingPeriod }}</b-td>
          <b-td>
            <track-link :track="match.Track" prefix="Track:" />
            <release-link :album="match.Album" prefix="Album:" />
            <label-link :label="match.Label" prefix="Label:" />
          </b-td>
          <b-td>
            {{ match.TransactionType.Description }}
            <template v-if="match.TransactionType.Description === null">Alle</template>
          </b-td>
          <b-td v-if="match.RuleGroups">
            <router-link 
                :to="{ name: 'accounting-rule-group', params: { id: group.TransactionReaccountRuleGroupID } }" 
                class="d-block" 
                v-for="group in match.RuleGroups" 
                :key="group.TransactionReaccountRuleGroupID">
              {{ group.Description }}
            </router-link>
          </b-td>
        </b-tr>
        <b-tr class="text-center">
          <b-td :colspan="showGroup ? 5 : 4">
            <b-button size="sm" @click="add()">add</b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <b-modal id="edit-match" title="Create/edit Match" hide-footer>
      <trx-match-edit @cancel="$bvModal.hide('edit-match')" />
    </b-modal>

  </div>
</template>

<script>

export default {
  name: 'TrxMatchList',
  components: {
    'track-link': () => import('@/components/TrackLink'),
    'release-link': () => import('@/components/ReleaseLink'),
    'label-link': () => import('@/components/LabelLink'),
    'trx-match-edit': () => import('@/components/TrxMatchEdit'),
  },
  props: {
    label: Object,
    release: Object,
    track: Object,
    matches: Array,
  },
  data () {
    return {
      data_matches: null,
    }
  },
  methods: {
    add () {

      this.$bvModal.show('edit-match')
    }
  },
  computed: {
    showGroup () {
      return this.data_matches && this.data_matches.length > 0 && this.data_matches[0].RuleGroups
    }
  },
  mounted () {
    if (this.matches) {
      this.data_matches = this.matches
      return
    }

    let filter = {}
    if (this.track) {
      filter.TrackReportingCode = this.track.ProductDetails.ReportingCode
    }

    if (this.release) {
      filter.AlbumReportingCode = this.release.ProductDetails.ReportingCode
    }

    if (this.label) {
      filter.SubProviderID = this.label.SubProviderID
    }

    this.$http.get('accounting/matches', { params: {
        'load-related': ['TransactionReaccountRuleGroup', 'Track', 'Album', 'SubProvider'],
        'filter': filter,
        'perPage': null,
      }})
      .then(response => {
        this.data_matches = response.data
      })
      .catch(() => {
        this.$notify({
          type: 'danger',
          text: 'Er ging iets mis bij het laden'
        });
      });
  }
}

</script>