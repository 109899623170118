var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-table-simple",
        { attrs: { small: "" } },
        [
          _c(
            "b-thead",
            [
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v("First period")]),
                  _c("b-th", [_vm._v("Last period")]),
                  _c("b-th", [_vm._v("Target")]),
                  _c("b-th", [_vm._v("Transaction Type")]),
                  _vm.showGroup ? _c("b-th", [_vm._v("Group")]) : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tbody",
            [
              _vm._l(_vm.data_matches, function(match) {
                return _c(
                  "b-tr",
                  { key: match.TransactionMatchID },
                  [
                    _c("b-td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatAccountingPeriod")(
                            match.FirstValidAccountingPeriodID
                          )
                        )
                      )
                    ]),
                    _c("b-td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatAccountingPeriod")(
                            match.LastValidAccountingPeriodID
                          )
                        )
                      )
                    ]),
                    _c(
                      "b-td",
                      [
                        _c("track-link", {
                          attrs: { track: match.Track, prefix: "Track:" }
                        }),
                        _c("release-link", {
                          attrs: { album: match.Album, prefix: "Album:" }
                        }),
                        _c("label-link", {
                          attrs: { label: match.Label, prefix: "Label:" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-td",
                      [
                        _vm._v(
                          " " + _vm._s(match.TransactionType.Description) + " "
                        ),
                        match.TransactionType.Description === null
                          ? [_vm._v("Alle")]
                          : _vm._e()
                      ],
                      2
                    ),
                    match.RuleGroups
                      ? _c(
                          "b-td",
                          _vm._l(match.RuleGroups, function(group) {
                            return _c(
                              "router-link",
                              {
                                key: group.TransactionReaccountRuleGroupID,
                                staticClass: "d-block",
                                attrs: {
                                  to: {
                                    name: "accounting-rule-group",
                                    params: {
                                      id: group.TransactionReaccountRuleGroupID
                                    }
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(group.Description) + " ")]
                            )
                          }),
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c(
                "b-tr",
                { staticClass: "text-center" },
                [
                  _c(
                    "b-td",
                    { attrs: { colspan: _vm.showGroup ? 5 : 4 } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm" },
                          on: {
                            click: function($event) {
                              return _vm.add()
                            }
                          }
                        },
                        [_vm._v("add")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "edit-match",
            title: "Create/edit Match",
            "hide-footer": ""
          }
        },
        [
          _c("trx-match-edit", {
            on: {
              cancel: function($event) {
                return _vm.$bvModal.hide("edit-match")
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }